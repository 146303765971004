import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import SEO from '../components/SEO';
import PortableText from '../components/PortableText';
import { mq } from '../styles/breakpoints';
import formatMoney from '../lib/formatMoney';
import BackArrow from '../assets/images/BackArrow.inline.svg';
import BirdPlus from '../assets/images/bird-plus.inline.svg';

const RealisationStyles = styled.div`
  padding-top: var(--margin);

  .block {
    a,
    a:hover {
      color: var(--white);
    }
  }

  .image {
    cursor: pointer;
  }

  .section-title {
    margin-bottom: 0.5rem;
    text-align: center;

    ${mq[2]} {
      margin-bottom: 0.25rem;
    }
  }
  .title-small {
    text-transform: uppercase;

    span {
      display: block;
      padding: 0.25rem;
    }

    &.place {
      text-align: center;
    }
  }
  .place {
    margin: 0 0 var(--padding);
  }
  .realisation-container {
    margin-bottom: var(--padding);

    svg {
      display: block;
      margin: 0 auto;
      max-width: 18.75rem;
    }
  }

  .details_realisation {
    > * {
      overflow: hidden;
    }

    ${mq[2]} {
      display: grid;
      grid-template-rows: 1fr 1.5fr 1.5fr 1fr;
      row-gap: 1.25rem;
    }
  }
  .bloc1,
  .bloc2,
  .bloc3,
  .bloc4 {
    display: grid;
    column-gap: 1.25rem;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }

  .image1,
  .bloc2--right,
  .big-stretch,
  .small--inner,
  .bloc2,
  .bloc3,
  .bloc4,
  .bloc4--right {
    margin-top: 1.25rem;

    ${mq[2]} {
      margin-top: 0;
    }
  }

  .bloc1 {
    ${mq[2]} {
      grid-template-columns: 2fr 3fr;
    }
  }
  .bloc2 {
    ${mq[2]} {
      grid-template-columns: 3fr 2fr;
    }
  }
  .bloc2--left {
    display: grid;
    grid-template-rows: 2fr 1fr;
    row-gap: 1.25rem;
  }
  .bloc2--right {
    display: grid;
    grid-template-rows: 1fr 2fr;
    row-gap: 1.25rem;
  }
  .bloc3 {
    ${mq[2]} {
      grid-template-columns: 2fr 2fr 1fr;
    }
  }
  .bloc4 {
    ${mq[2]} {
      grid-template-columns: 3fr 2fr;
    }
  }
  .bloc4--left {
    display: grid;
  }
  .bloc4--right {
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 1.25rem;
  }
  .description {
    background: var(--blue);
    color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.625rem 1.875rem;
    text-align: center;

    .title-small {
      margin-bottom: 1rem;
      padding-bottom: 0.75rem;
      position: relative;
      text-transform: none;

      &:after {
        background-color: var(--white);
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 1.875rem;
      }
    }
  }

  .gatsby-image-wrapper-constrained {
    display: block;

    img {
      display: block;
    }
  }
  .travaux {
    display: grid;
    row-gap: 1.25rem;
    grid-template-rows: 1fr 2fr;

    h3 {
      margin-bottom: 1rem;
    }

    ${mq[0]} {
      column-gap: 1.25rem;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 2fr;
    }
  }
  .mission {
    background-color: var(--rose);
    color: var(--white);
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    font-weight: bold;
    justify-content: center;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;

    p {
      margin: 0;
    }
  }
  .budget {
    align-items: center;
    background: var(--blue);
    color: var(--white);
    display: flex;
    justify-content: center;
    padding: 24px 44px;
    text-align: center;

    .title-small {
      margin-bottom: 0.5rem;
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
  .loyer-number,
  .budget-number {
    font-size: 2rem;
    font-weight: bold;
  }
  .loyer {
    align-items: center;
    background-color: var(--rose);
    color: var(--white);
    display: flex;
    justify-content: center;
    text-align: center;

    .loyer-number {
      margin-top: 0.5rem;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }
  .small {
    display: grid;
    grid-template-rows: 1fr 0.5fr;
    row-gap: 1.25rem;
  }
  .small--inner {
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 1.25rem;
  }
  .big-normal {
    display: grid;
    grid-template-rows: 1.5fr;
  }
  .big-stretch {
    display: grid;
    row-gap: 1.25rem;
    grid-template-rows: 1fr 0.5fr;
  }
  .more {
    row-gap: 1.25rem;
    display: grid;
    grid-template-rows: 1fr 1fr;

    ${mq[0]} {
      column-gap: 1.25rem;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
    }
  }
  .more--link {
    align-items: center;
    background-color: var(--blue);
    color: var(--white);
    display: flex;
    justify-content: center;
    position: relative;

    p {
      transform: translateY(1.5em);
    }
  }
  .bird-plus {
    height: 35%;
    position: absolute;
    left: 50%;
    transform: translate(-42%, -50%);
  }
`;

export default function SingleRealisationPage({ data, location }) {
  const { realisation, realisations } = data;
  //   console.log(realisation.image1.full.gatsbyImageData.images.fallback.src);

  //   Get slug from another random realization
  const random = Math.floor(Math.random() * realisations.totalCount);

  let nextRealisation;

  if (realisations.nodes[random].slug.current !== realisation.slug.current) {
    nextRealisation = realisations.nodes[random]?.slug.current;
  } else if (random + 1 < realisations.totalCount) {
    nextRealisation = realisations.nodes[random + 1]?.slug.current;
  } else {
    nextRealisation = realisations.nodes[random - 1]?.slug.current;
  }

  return (
    <SimpleReactLightbox>
      <SEO
        title={realisation.titleSeo || realisation.title}
        description={realisation.descriptionSeo}
        location={location}
        image={realisation.image1.full.gatsbyImageData.images.fallback.src}
      />
      <SRLWrapper>
        <RealisationStyles>
          <div className="container realisation-container">
            <Link to="/realisations" className="back">
              <BackArrow className="back__arrow" />
              <span className="back__label">Toutes les réalisations</span>
            </Link>
            <h1 className="section-title">{realisation.title}</h1>
            <h2 className="place title-small">{realisation.place}</h2>
            <div className="details_realisation">
              <section className="bloc1">
                <div className="description text">
                  <div className="inner">
                    <h2 className="title-small">
                      <span>{realisation.subtitle}</span>
                      <span>{realisation.surface} m²</span>
                    </h2>
                    <h3>Mission Early Birds</h3>
                    <PortableText
                      blocks={realisation.realisationExcerpt._rawText}
                      id="excerpt"
                    />
                  </div>
                </div>
                <div className="image image1">
                  <a
                    href={
                      realisation.image1.full.gatsbyImageData.images.fallback
                        .src
                    }
                  >
                    <GatsbyImage
                      image={getImage(realisation.image1.normal)}
                      alt={
                        realisation.image1.normal.altText || realisation.title
                      }
                      srl_gallery_image="true"
                    />
                  </a>
                </div>
              </section>
              <section className="bloc2">
                <div className="bloc2--left">
                  <div className="image image2">
                    <a
                      href={
                        realisation.image2.full.gatsbyImageData.images.fallback
                          .src
                      }
                    >
                      <GatsbyImage
                        image={getImage(realisation.image2.normal)}
                        alt={
                          realisation.image2.normal.altText || realisation.title
                        }
                        srl_gallery_image="true"
                      />
                    </a>
                  </div>
                  <div className="travaux">
                    <div className="mission text">
                      <div className="inner">
                        <h3>Travaux réalisés</h3>
                        <p>{realisation.description}</p>
                      </div>
                    </div>
                    <div className="image image3">
                      <a
                        href={
                          realisation.image3.full.gatsbyImageData.images
                            .fallback.src
                        }
                      >
                        <GatsbyImage
                          image={getImage(realisation.image3.normal)}
                          alt={
                            realisation.image3.normal.altText ||
                            realisation.title
                          }
                          srl_gallery_image="true"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="bloc2--right">
                  <div className="image image4">
                    <a
                      href={
                        realisation.image4.full.gatsbyImageData.images.fallback
                          .src
                      }
                    >
                      <GatsbyImage
                        image={getImage(realisation.image4.normal)}
                        alt={
                          realisation.image4.normal.altText || realisation.title
                        }
                        srl_gallery_image="true"
                      />
                    </a>
                  </div>
                  <div className="image image5">
                    <a
                      href={
                        realisation.image5.full.gatsbyImageData.images.fallback
                          .src
                      }
                    >
                      <GatsbyImage
                        image={getImage(realisation.image5.normal)}
                        alt={
                          realisation.image5.normal.altText || realisation.title
                        }
                        srl_gallery_image="true"
                      />
                    </a>
                  </div>
                </div>
              </section>
              <section className="bloc3">
                <div className="big big-normal">
                  <div className="image image6">
                    <a
                      href={
                        realisation.image6.full.gatsbyImageData.images.fallback
                          .src
                      }
                    >
                      <GatsbyImage
                        image={getImage(realisation.image6.normal)}
                        alt={
                          realisation.image6.normal.altText || realisation.title
                        }
                        srl_gallery_image="true"
                      />
                    </a>
                  </div>
                </div>
                <div className="big big-stretch">
                  <div className="image image7">
                    <a
                      href={
                        realisation.image7.full.gatsbyImageData.images.fallback
                          .src
                      }
                    >
                      <GatsbyImage
                        image={getImage(realisation.image7.normal)}
                        alt={
                          realisation.image7.normal.altText || realisation.title
                        }
                        srl_gallery_image="true"
                      />
                    </a>
                  </div>
                  <div className="budget text">
                    <div className="inner">
                      <h3 className="title-small">Budget global</h3>
                      <h4 className="budget-number">
                        {formatMoney(realisation.budget)}
                      </h4>
                      <p>{realisation.budgetIncludes}</p>
                    </div>
                  </div>
                </div>
                <div className="small">
                  <div className="small--inner">
                    <div className="image image8">
                      <a
                        href={
                          realisation.image8.full.gatsbyImageData.images
                            .fallback.src
                        }
                      >
                        <GatsbyImage
                          image={getImage(realisation.image8.normal)}
                          alt={
                            realisation.image8.normal.altText ||
                            realisation.title
                          }
                          srl_gallery_image="true"
                        />
                      </a>
                    </div>
                    <div className="image image8b">
                      <a
                        href={
                          realisation.image9.full.gatsbyImageData.images
                            .fallback.src
                        }
                      >
                        <GatsbyImage
                          image={getImage(realisation.image9.normal)}
                          alt={
                            realisation.image9.normal.altText ||
                            realisation.title
                          }
                          srl_gallery_image="true"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="loyer text">
                    <div className="inner">
                      <h3 className="title-small">Loyer mensuel</h3>
                      <p className="loyer-number">
                        {formatMoney(realisation.loyer)}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="bloc4">
                <div className="bloc4--left">
                  <div className="image image9">
                    <a
                      href={
                        realisation.image10.full.gatsbyImageData.images.fallback
                          .src
                      }
                    >
                      <GatsbyImage
                        image={getImage(realisation.image10.normal)}
                        alt={
                          realisation.image10.normal.altText ||
                          realisation.title
                        }
                        srl_gallery_image="true"
                      />
                    </a>
                  </div>
                </div>
                <div className="bloc4--right">
                  <div className="image image10">
                    <a
                      href={
                        realisation.image11.full.gatsbyImageData.images.fallback
                          .src
                      }
                    >
                      <GatsbyImage
                        image={getImage(realisation.image11.normal)}
                        alt={
                          realisation.image11.normal.altText ||
                          realisation.title
                        }
                        srl_gallery_image="true"
                      />
                    </a>
                  </div>
                  <div className="more">
                    <div className="image image11">
                      <a
                        href={
                          realisation.image12.full.gatsbyImageData.images
                            .fallback.src
                        }
                      >
                        <GatsbyImage
                          image={getImage(realisation.image12.normal)}
                          alt={
                            realisation.image12.normal.altText
                              ? realisation.image12.normal.altText
                              : realisation.title
                          }
                          srl_gallery_image="true"
                        />
                      </a>
                    </div>
                    <Link
                      to={`/realisations/${nextRealisation}`}
                      className="more--link"
                    >
                      <BirdPlus className="bird-plus" />
                      <p>Autre réalisation</p>
                    </Link>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </RealisationStyles>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
}

export const query = graphql`
  query ($id: String!) {
    realisation: sanityRealisation(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      titleSeo
      descriptionSeo
      place
      subtitle
      surface
      realisationExcerpt {
        _rawText(resolveReferences: { maxDepth: 10 })
      }
      description
      budget
      budgetIncludes
      loyer
      image1 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 350)
          altText
        }
      }
      image2 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 350)
          altText
        }
      }
      image3 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 278)
          altText
        }
      }
      image4 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 288)
          altText
        }
      }
      image5 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 600)
          altText
        }
      }
      image6 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 835)
          altText
        }
      }
      image7 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 578)
          altText
        }
      }
      image8 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 600)
          altText
        }
      }
      image9 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 600)
          altText
        }
      }
      image10 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 350)
          altText
        }
      }
      image11 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 288)
          altText
        }
      }
      image12 {
        full: asset {
          gatsbyImageData(width: 2000, layout: FULL_WIDTH)
          altText
        }
        normal: asset {
          gatsbyImageData(width: 600, height: 600)
          altText
        }
      }
    }
    realisations: allSanityRealisation {
      totalCount
      nodes {
        slug {
          current
        }
      }
    }
  }
`;
